@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Design Design" , sans-serif;
  overflow-x:hidden;
}

.flex-card {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 24px;
}


.bg-purple {
  border-radius: 8px;
  background: var(--bg-btn, linear-gradient(186deg, #90C 57.71%, #540070 92.92%));
  color: #99FF00;
}


.your-bg {
  border-radius: 24px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: var(--bg-card2-gradient, linear-gradient(180deg, rgba(153, 0, 204, 0.00) 0%, #90C 100%)), var(--green, #9F0);
    border: 7px solid #9F0;

}

.bg-2{
  border-radius: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: var(--bg-card2-gradient, linear-gradient(180deg, #90C 0%, rgba(153, 0, 204, 0.00) 100%)), var(--green, #9F0);
  border: 6px solid #90C; 
}


.bg-gradient-6 {
  background: linear-gradient(to bottom,#0e182b 0,#0000 100%);
}

.bg-gradient-gray {
  background-image: -webkit-linear-gradient(#fff,#9f9f9f);
}

.btn-primary{

  border-radius: 8px;
  background: var(--bg-btn, linear-gradient(186deg, #90C 57.71%, #540070 92.92%));

  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: #99FF00;
  --tw-shadow: 0 25px 50px -12pxrgba(0,0,0,.25);
  --tw-shadow-colored: 0 25px 50px -12pxvar(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  --tw-shadow-color: rgba(22,78,99,.5);
  --tw-shadow: var(--tw-shadow-colored);
  transition-property: color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}

#toggle-animation {
  background-color: rgba(6, 27, 45, 0.6);
  min-width: initial;
  border: none;
  color: #8c9aa2;
  z-index: 2;
}

.view-more {
  z-index: 2;
}
.hero-container {
  margin-top: 23vh;
  z-index: 2;
}
.hero-container h2 {
  font-size: 1.375rem;
  font-weight: 200;
  font-family: 'inter', sans-serif;
  line-height: 2rem;
}
@media (max-width: 768px) {
  .hero-container {
    margin-top: 15vh;
  }
}
@media (max-width: 640px) {
  .hero-container h2 {
    font-size: 1.1625rem;
    line-height: 1.6rem;
  }
  .hero-container {
    margin-top: 5vh;
    padding: 0 10%;
  }
}
@media (max-width: 420px) {
  #stars {
    min-height: 44rem;
  }
}


@font-face {
  font-family: "Design Design";
  src: url(./fonts/design-design-700.otf);
}